import React from 'react';
import { useTranslation } from 'react-i18next';
import './Legislation.css'

const PrivacyLegislation = () => {
    const {t} = useTranslation()
  const legislations =  [
    {
      "id": 1,
      "language": "Odluka VM Montrealski 67 15",
      "filename": "legislation/Odluka VM Montrealski 67 15.pdf"
    },
    {
      "id": 2,
      "language": "Odluka VM Montrealski br. 36 07",
      "filename": "legislation/Odluka VM Montrealski br. 36 07.pdf"
    },
    {
      "id": 3,
      "language": "Pravilnik o postepenom iskljucivanju supstanci koje ostecu…_0_0",
      "filename": "legislation/Pravilnik o postepenom iskljucivanju supstanci koje ostecu…_0_0.pdf"
    },
    {
      "id": 4,
      "language": "Zakon o zastiti zraka_72_24",
      "filename": "legislation/Zakon o zastiti zraka_72_24.pdf"
    }
  ]
  const basePath = window.location.hostname === 'localhost' ? "https://localhost:7111/" : "/";

  return (
    <>
    <div className="details-overlay overlay-1">
        <p className="title-text">
          {t(
            "legislation-page:title",
            "Legislation"
          )}
        </p>
      </div>
    <div className="min-h-screen bg-gradient-to-b  to-white p-8">
      <div className="mx-auto" style={{width:'90%'}}>
      <div className="text-gray-600 leading-relaxed p-6">
       </div>
        
        <div className="">
          {legislations.map((item) => (
            <div key={item.id} className="p-6 border-b border-gray-200 last:border-b-0">
              <h2 className="text-xl font-semibold  mb-3" style={{color:'#055E9A',cursor:'pointer'}}   onClick={() =>
                        window.open(basePath+item.filename, "_blank")}>
                {item.id}. {item.language}
              </h2>
           
            </div>
          ))}
          
          <div className="p-6 text-sm text-gray-500 italic">
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default PrivacyLegislation;