import React, { useContext, useEffect } from "react";
import "./search-style.css";
import { CommonDataContext } from "contexts/CommonDataContext/CommonDataContext";
import { Button, Form, Input, Select } from "antd";
import { SearchOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import { MunicipalityDto } from "api/models";
import { PublicApi } from "api/api";

const { Option } = Select;
const publicApi = new PublicApi();

const SearchComponent = ({ type,onResponse,id }: any) => {
  const { t } = useTranslation();

  const commonData = useContext(CommonDataContext) as any;
  const { municipalities } = commonData;

  const handleSearch = async (data: any) => {
    onResponse({ data: [], isLoading: true });
    const request = await publicApi.apiPublicRegistersPost({
      getRegistersForPublicQuery: {...data,companyType:type,entityId:id},
    });
    onResponse({ data: request.data, isLoading: false });
  };

  useEffect(() => {
    handleSearch({});
  }, []);

  return (
    <div className="search-container">
      <Form
        name="searchForm"
        className="search-container-form"
        onFinish={handleSearch}
      >
        <Form.Item name="search">
          <Input
            type="text"
            className="input"
            placeholder={t(
              "public-search:input-placeholder",
              "What are you looking for?"
            )}
          />
        </Form.Item>
        <Form.Item name="MunicipalityId"  className=" input1">
          <Select
            placeholder={t("search:select-municipality", "Select municipality")}
            size="large"
            id=""
          >
            {municipalities &&
              municipalities.map((item: MunicipalityDto) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
          </Select>
        </Form.Item>

        <Button
          type="primary"
          className="serach-btn"
          htmlType="submit"
          style={{
            borderRadius: '10px',
            backgroundColor: '#FEE100',
            width: '60px',
            marginLeft: '5px'
          }}
        >
          <SearchOutlined color="#000000" style={{ fontSize: '30px', fontWeight: '600',color:'#000000' }} />
        </Button>
      </Form>
    </div>
  );
};

export default SearchComponent;
