import React from "react";
import { Menu, Dropdown } from "antd";
import { NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CustomContextMenu = ({ isRequestPage }: { isRequestPage: boolean }) => {
  const { i18n, t } = useTranslation();

  const contextMenu = (
    <Menu className="request-menu">
      <Menu.Item key="1">
        <NavLink tag={Link} style={{fontSize:'20px'}}  to="/requests/create/1">
          {t("menu:owners-operators", "Owners And Operators Of KGH Equipment")}
        </NavLink>
      </Menu.Item>
      <Menu.Item key="2">
        <NavLink tag={Link} style={{fontSize:'20px'}} to="/requests/create/2">
          {t(
            "menu:companies-entrepreneurs",
            "KGH Service Companies/Entrepreneurs"
          )}
        </NavLink>
      </Menu.Item>
      <Menu.Item key="3">
        <NavLink tag={Link} style={{fontSize:'20px'}} to="/requests/create/3">
          {t(
            "menu:importers-exporters",
            "Importers/Exporters Of KGH Equipment"
          )}
        </NavLink>
      </Menu.Item>
    </Menu>
  );

  const languageMenu = (
    <Menu>
      <Menu.Item
        key={1}
        onClick={() =>
          handleLanguage("bs", "64924d45-7f38-11ee-85aa-0242ac110004")
        }
      >
        Bosanski
      </Menu.Item>
      <Menu.Item
        key={2}
        onClick={() =>
          handleLanguage("hr", "64925194-7f38-11ee-85aa-0242ac110004")
        }
      >
        Hrvatski
      </Menu.Item>
      <Menu.Item
        key={3}
        onClick={() =>
          handleLanguage("sr", "64925080-7f38-11ee-85aa-0242ac110004")
        }
      >
        Srpski
      </Menu.Item>
      <Menu.Item
        key={4}
        onClick={() =>
          handleLanguage("en", "649251f1-7f38-11ee-85aa-0242ac110004")
        }
      >
        English
      </Menu.Item>
    </Menu>
  );

  const handleLanguage = (lng: string, id: string) => {
    localStorage.setItem("i18nextLng", lng);
    localStorage.setItem("i18nextLanguageId", id);
    i18n.changeLanguage(lng);
  };

  return (
    <div
      className="flex md:flex-row flex-col w-full bg-blue md:justify-end md:items-center"
      style={{ width: "100%" }}
    >
      <Dropdown overlay={languageMenu} trigger={["click"]}>
        <div
          className={`
             text-light hover:bg-slate-700
             flex flex-row items-center cursor-pointer text-xl md:text-base px-3 py-2 rounded-md`}
          style={{ marginRight: '10px' }}
        >
          <svg
            width="30"
            height="30"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-globe "
          >
            <g>
              <circle cx="12" cy="12" r="10"></circle>
              <line x1="2" y1="12" x2="22" y2="12"></line>
              <path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path>
            </g>
          </svg>

          <span className="pl-2  md:text-base language-div" style={{ fontSize: '20px' }} >
            {localStorage.getItem("i18nextLng")?.toUpperCase() || "BS"}
          </span>
        </div>
      </Dropdown>
      <Dropdown overlay={contextMenu} trigger={["click"]}  >
        <div style={{ backgroundColor: '#FEE100',borderRadius:'15px' }} className=" text-light hover:bg-blue-800 flex flex-row items-center cursor-pointer bg-blue-600 text-xl md:text-base rounded-md pr-3">
          <span className="bg-blue-600 rounded-bl-md rounded-tl-md" style={{ backgroundColor: '#FEE100', color: '#000000E5',borderRadius:'15px' }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="white" /* Set icon color to white */
              width="53px"
              height="53px"
              style={{ backgroundColor: '#013D65', padding: '10px', borderRadius: '15px' }} /* Set background color and optional border-radius */
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4.5v15m7.5-7.5h-15"
              />
            </svg>
          </span>
          <span className="p-2 text-xl md:text-base" style={{ color: '#000000E5',fontSize:'20px',lineHeight:'32px',letterSpacing:'0.02em' }}>
            {t("menu:request-tab", "Request")}
          </span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="black" /* Set stroke to black */
            style={{ width: 16, height: 16 }}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
            />
          </svg>

        </div>
      </Dropdown>
    </div>
  );
};

export default CustomContextMenu;
