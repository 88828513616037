import React, { useEffect, useRef,useState } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5map from '@amcharts/amcharts5/map';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

// Import geodata for Bosnia and Herzegovina
import am5geodata_bosniaHerzegovinaLow from '@amcharts/amcharts5-geodata/bosniaHerzegovinaLow';
import { FaMapMarkerAlt } from 'react-icons/fa';

// Define an interface for the country data
interface CountryData {
  name: string; // Add other properties as needed
  hoverColor?: string; // Optional hover color for each country
}

interface MapChartProps {
  getEntityName: (name: string) => void; // Function to handle entity name retrieval
  entityName : string
}


const MapChart: React.FC<MapChartProps> = ({getEntityName,entityName}) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const rootRef = useRef<am5.Root | null>(null);
  const [series, setSeries] = useState<am5map.MapPolygonSeries | null>(null);

  // const getTranslatedName = (name: string) => {
  //   switch (name) {
  //     case 'Republika Srpska':
  //       return t('entities.republikaSrpska');
  //     case 'Federacija Bosne i Hercegovine':
  //       return t('entities.federacijaBiH');
  //     case 'Brčko distrikt':
  //       return t('entities.brckoDistrikt');
  //     default:
  //       return name;
  //   }
  // };

  const getEntityColor = (name: string): number => {
    switch (name) {
      case 'Republika Srpska':
        return 0xe6ccff;
      case 'Federacija Bosne i Hercegovine':
        return 0xaaaaaa;
      case 'Brčko distrikt':
        return 0xffcccb;
      default:
        return 0xffffcc;
    }
  };
  

  useEffect(() => {
    const root = am5.Root.new(chartRef.current!);
    const colors = am5.ColorSet.new(root, {});

    root.setThemes([am5themes_Animated.new(root)]);

    const chart = root.container.children.push(am5map.MapChart.new(root, {
      panX: 'none',
      panY: 'none',
      wheelX: 'none',
      wheelY: 'none',
      projection: am5map.geoMercator()
    }));

    const countrySeries = chart.series.push(am5map.MapPolygonSeries.new(root, {
      geoJSON: am5geodata_bosniaHerzegovinaLow
    }));

    
    setSeries(countrySeries);

    // Set default color for the polygons
    countrySeries.mapPolygons.template.setAll({
      fill: am5.color(0xffffcc), // Default light yellow color
      stroke: am5.color(0xaaaaaa), // Default border color
      strokeWidth: 1,
      tooltipText: '{name}', // Default tooltip text
      interactive: true,
      templateField: 'polygonSettings'
    });


    countrySeries.mapPolygons.template.events.on("pointerover", (ev) => {
      const dataItem = ev.target.dataItem; 
      if (dataItem) {
        const countryData = dataItem.dataContext as CountryData; 
        ev.target.set("fill", am5.color(getEntityColor(countryData.name)));
        // Change to a different color on hover
        ev.target.set("stroke", am5.color(0xff0000)); // Set border to red on hover
        ev.target.set("strokeWidth", 2); // Increase border width on hover
      
        
      }
   
    });
    

    // Reset color and border when mouse leaves
    countrySeries.mapPolygons.template.events.on("pointerout", (ev) => {
      const dataItem = ev.target.dataItem; 
      if (dataItem) {
      const countryData = dataItem.dataContext as CountryData; 
      ev.target.set("fill", am5.color(entityName === countryData.name ? getEntityColor(countryData.name) : 0xffffcc));

      }
    });

    // Handle click event
    countrySeries.mapPolygons.template.events.on('click', (ev) => {
      const dataItem = ev.target.dataItem; 
      if (dataItem) {
        const countryData = dataItem.dataContext as CountryData; 
       getEntityName(countryData?.name); // Log the country name on click
      }
    });

    countrySeries.mapPolygons.each((polygon) => {
      const countryData = polygon.dataItem?.dataContext as CountryData;
      if (countryData && countryData.name === entityName) {
        polygon.set("fill", am5.color(getEntityColor(countryData.name)));
      }
    });

    return () => {
      root.dispose();
    };
  }, []);

  useEffect(() => {
    
    if (series && entityName) {
      series.mapPolygons.each((polygon) => {
        const countryData = polygon.dataItem?.dataContext as CountryData;
        
        if (countryData) {
          polygon.set("fill", am5.color(entityName === countryData.name ? getEntityColor(countryData.name) : 0xffffcc));
          polygon.set("stroke", am5.color(entityName === countryData.name ? 0xff0000 : 0xaaaaaa)); // Set border to red on hover
        polygon.set("strokeWidth", 2); // Increase border width on hover
        }
      });
    }
  }, [entityName,series]);

  

  // return <div id="chartdiv" ref={chartRef} style={{ width: '100%', height: '500px' }} />;
  return (
    <div style={{ position: 'relative', width: '100%', height: '500px' }}>
      {/* Top-left map icon */}
      <div
        style={{
          position: 'absolute',
          top: '10px',
          left: '10px',
          zIndex: 10,
          cursor: 'pointer',
          backgroundColor: '#fff',
          padding: '5px',
          borderRadius: '50%',
        }}
        onClick={() => getEntityName('')} // Reset entityId when clicked
      >
        <FaMapMarkerAlt size={24} color="#055E9A" />
      </div>

      {/* Map container */}
      <div id="chartdiv" ref={chartRef} style={{ width: '100%', height: '500px' }} />
    </div>
  );
};

export default MapChart;
