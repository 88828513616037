import React from 'react';
import { FaLinkedin, FaInstagram, FaFacebookF, FaTwitter, FaYoutube, FaSkype, FaSnapchatGhost, FaTiktok, FaWhatsapp, FaGlobe } from 'react-icons/fa';
import { TfiEmail } from 'react-icons/tfi';
import './Footer.css'
import { BsTelephoneOutbound } from "react-icons/bs";
import { Divider } from 'antd';
import { PiMediumLogoFill } from "react-icons/pi";
import { FaWhatsappSquare, FaInstagramSquare } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa6";
import { BsTwitterX } from "react-icons/bs";
import { RiMediumFill } from "react-icons/ri";
import { BsGlobe } from "react-icons/bs";
import { FaSnapchatSquare } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaFacebookSquare } from "react-icons/fa";
import { useTranslation } from 'react-i18next';

const SocialIcons = ({ icons }) => (
  <div className="flex space-x-2">
    {icons.map((Icon, index) => (
      <Icon key={index} className="text-white text-xl hover:text-gray-300 cursor-pointer" />
    ))}
  </div>
);

const CustomFooter = () => {

  const { t } = useTranslation();
  return (
    <>
      <svg width="0" height="0">
        <linearGradient id="instagram-gradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" style={{ stopColor: '#fdf497', stopOpacity: 1 }} />
          <stop offset="50%" style={{ stopColor: '#fd5949', stopOpacity: 1 }} />
          <stop offset="75%" style={{ stopColor: '#d6249f', stopOpacity: 1 }} />
          <stop offset="100%" style={{ stopColor: '#285AEB', stopOpacity: 1 }} />
        </linearGradient>
      </svg>
      <footer className="footer">
        <div className="container mx-auto max-w-[1400px] w-full pt-[50px] pb-[50px]">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <div>
              <h2 className="mb-4 text-[46px] text-white">KGH</h2>
              <p className="footer-description-text">
             
              {t("footer:description", "On this website, you can access detailed information about KGH device owners, importers/exporters, service companies, and certified technicians. You can explore data based on administrative and geographic areas; view updates related to KGH systems and submit a request to register your company. The platform also provides open data in machine-readable formats for public use.")}
              </p>
            </div>
            <div className='quick-links-div'>
              <h3 className="quick-links">{t("footer:quick-links", "Quick Links")}</h3>
              <ul className="options-div">
                <li><a href="/" className="options">{t("navbar:navigation-options:home", "Home")} <FaChevronRight className="right-arow" /></a></li>
                <li><a href="/report" className="options">{t("navbar:navigation-options:report", "Substance Report")} <FaChevronRight className="right-arow" /></a></li>
                <li><a href="/legislation" className="options">{t("navbar:navigation-options:legislation", "Legislation")} <FaChevronRight className="right-arow" /></a></li>
              </ul>
            </div>
            <div className='contact-links-div'>
              <h3 className="contact-links">{t("footer:contact-us", "Contact us")}</h3>
              {/* <div className="contact-div">
            {['ministry-of-foreign-trade', 'federation-of-bosnia', 'republic-of-srpska', 'brcko-district'].map((title) => (
              <div className='contact-card' key={title}>
                <p className="mb-2 title">{t(`footer:${title}`, title.replace(/-/g, ' '))}</p>
                <div className="flex justify-between max-w-[194px] w-full">
                  <FaLinkedin color='#117EB8' className="contact-icons" />
                  <FaInstagramSquare className="instagram-icon contact-icons" />
                  <FaFacebookSquare color='#1976D2' className="contact-icons" />
                  <FaSquareXTwitter color='#000000' className="contact-icons" />
                  <FaYoutube color='#FD3832' className="contact-icons" />
                </div>
              </div>
            ))}
          </div> */}
              <div className="contact-div">
                {['ministry-of-foreign-trade'].map((title) => (
                  <div className='contact-card' key={title}>
                    <p className="mb-2 title">{t(`footer:${title}`, title.replace(/-/g, ' '))}</p>
                    <div className="flex justify-between max-w-[70%] w-full">
                      {/* <FaLinkedin color='#117EB8' className="contact-icons" />
                  <FaInstagramSquare className="instagram-icon contact-icons" />
                  <FaFacebookSquare color='#1976D2' className="contact-icons" />
                  <FaSquareXTwitter color='#000000' className="contact-icons" />
                  <FaYoutube color='#FD3832' className="contact-icons" /> */}
                      <BsTelephoneOutbound color='#FFF' size={16} />
                      <a href="tel:+ 387 33 220 093" className='number-link'>+ 387 33 220 093</a><span className='number-link'>|</span><a href="tel:+ 387 33 214 102" className='number-link' > + 387 33 214 102</a>  
                      
                    </div>
                    
                  </div>
                ))}
                {['federation-of-bosnia'].map((title) => (
                  <div className='contact-card' key={title}>
                    <p className="mb-2 title">{t(`footer:${title}`, title.replace(/-/g, ' '))}</p>
                    <div className="flex justify-between max-w-[194px] w-full">
                      <a href="https://www.facebook.com/p/Fond-za-za%C5%A1titu-okoli%C5%A1a-FBiH-100066393443346/" target='_blank'>
                        <FaFacebookSquare color='#1976D2' className="contact-icons" />
                      </a>
                     
                      {/* <FaInstagramSquare className="instagram-icon contact-icons" />
                  <FaFacebookSquare color='#1976D2' className="contact-icons" />
                  <FaSquareXTwitter color='#000000' className="contact-icons" />
                  <FaYoutube color='#FD3832' className="contact-icons" /> */}
                    </div>
                  <div className="flex justify-between max-w-[154px] w-full mt-3">
                  <BsTelephoneOutbound color='#FFF' size={16} />
                      <a href="tel:+ 387 (0)33 723 680" className='number-link'>+ 387 (0)33 723 680</a>
                  </div>
                  </div>
                ))}
                {['republic-of-srpska'].map((title) => (
                  <div className='contact-card' key={title}>
                    <p className="mb-2 title">{t(`footer:${title}`, title.replace(/-/g, ' '))}</p>
                    <div className="flex justify-between max-w-[194px] w-full">
                      <a href="https://www.instagram.com/ekofondrs/" target='_blank'>
                        <FaInstagramSquare className="instagram-icon contact-icons" />
                      </a>
                      <a href="https://www.facebook.com/ekofondrs" target='_blank'>
                        <FaFacebookSquare color='#1976D2' className="contact-icons" />
                      </a>
                      <a href="https://www.linkedin.com/company/environmental-protection-and-energy-efficiency-fund-of-the-republic-of-srpska/" target='_blank'>
                        <FaLinkedin color='#117EB8' className="contact-icons" />
                      </a>
                      <a href="https://www.youtube.com/@ekofondrs" target='_blank'>
                        <FaYoutube color='#FD3832' className="contact-icons" />
                      </a>


                      {/* <FaSquareXTwitter color='#000000' className="contact-icons" /> */}

                    </div>
                    <div className="flex justify-between max-w-[114px] w-full mt-3">
                  <BsTelephoneOutbound color='#FFF' size={16} />
                      <a href="tel:051/ 231-340"  className='number-link'>051/ 231-340</a>
                  </div>
                  </div>
                ))}
                {['brcko-district'].map((title) => (
                  <div className='contact-card' key={title}>
                    <p className="mb-2 title">{t(`footer:${title}`, title.replace(/-/g, ' '))}</p>
                    <div className="flex justify-between max-w-[194px] w-full">
                      <a href="https://www.facebook.com/wwwbdcentral.net/" target='_blank'>
                        <FaFacebookSquare color='#1976D2' className="contact-icons" />
                      </a>
                      {/* <FaLinkedin color='#117EB8' className="contact-icons" />
                  <FaInstagramSquare className="instagram-icon contact-icons" />
                  <FaFacebookSquare color='#1976D2' className="contact-icons" />
                  <FaSquareXTwitter color='#000000' className="contact-icons" />
                  <FaYoutube color='#FD3832' className="contact-icons" /> */}
                    </div>
                    <div className="flex justify-between max-w-[164px] w-full mt-3">
                  <BsTelephoneOutbound color='#FFF' size={16} />
                      <a href='tel:+ 387 (0) 49 / 240-817' className='number-link'>+ 387 (0) 49 / 240-817</a>
                  </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <Divider className="footer-divider" />
          <div className='mail-div'>
            <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4 mb-4 md:mb-0">
              <a href="mailto:info@mvteo.gov.ba" className="nav-icon flex items-center">
                <TfiEmail color="#055E9A" className="mail-icon" />info@mvteo.gov.ba
              </a>
              <a href="mailto:info@fzofbih.org.ba" className="nav-icon flex items-center">
                <TfiEmail color="#055E9A" className="mail-icon" />info@fzofbih.org.ba
              </a>
            </div>
            <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4">
              <a href="mailto:info@ekofondrs.org" className="nav-icon flex items-center">
                <TfiEmail color="#055E9A" className="mail-icon" />info@ekofondrs.org
              </a>
              {/* <a href="mailto:" className="nav-icon flex items-center">
              <TfiEmail color="#055E9A" className="mail-icon" />
            </a> */}
            </div>
          </div>
          <div>

          </div>
        </div>
        <div className="copyright-text">
          <p>{t("footer:copyright", "Copyright")} © 2024 KGH. {t("footer:allrights-by", "All Rights Reserved by")} <a className='footer-kgh' href='/'>KGH</a></p>
        </div>
      </footer>
    </>

  );

};

export default CustomFooter;