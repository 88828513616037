import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { useLocation } from 'react-router-dom';
import CustomFooter from './footer/Footer';

export const Layout = ({ children }) => {

  const [isRequestPage, setIsRequestPage] = useState(false)
  const location = useLocation();


  useEffect(() => {
    setIsRequestPage(location.pathname.includes('requests/create') || location.pathname.includes('register'));
  }, [location]);

  return (
    <div style={{backgroundColor:'#fff'}}>
      <NavMenu />
      {isRequestPage ? children :
        (<div style={{ padding: 0,margin:0 }} tag="main">
          {children}
        </div>)}
        <CustomFooter />
    </div>
  );
};

