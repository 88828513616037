import { Card, Col, Collapse, Row, Spin, Button, Modal, Typography,Tooltip,Form,Select,Skeleton,Divider,Table } from "antd";
import { PublicApi } from "api/api";
// import { PageHeader } from "components/page-headers/page-headers";
import { CardToolbox, Main, PageHeaderWrapper,TableWrapper } from "container/styled";
import { useEffect, useState } from "react";
import { useParams,Link } from "react-router-dom";
import moment from "moment";
import styled from "styled-components";
import { ColumnsType } from "antd/lib/table/interface";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import { Modal } from "components/modals/antd-modals";
// import EquipmentForm, { EquipmentViewMode } from "pages/equipments/components/EquipmentForm";

import FeatherIcon from "feather-icons-react";
import { RegisterDetailsDto } from "api/models/register-details-dto";
import { EquipmentDto } from "api/models/equipment-dto";
import { BranchWithEquipmentsDetailsDto } from "api/models/branch-with-equipments-details-dto";
import "./SubstanceReport.css";
import EquipmentForm from "components/register/details/EquipmentForm";
const { Panel } = Collapse;
const { Title, Text } = Typography;
const { Option } = Select;
const Loader = styled.div`
  display: flex;
  height: 400px;
  width: 100%;
  justify-content: center;
  justifyitems: center;
  align-items: center;
`;

const CardKeyValue = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  span {
    color: #323548;
    font-size: 13px;
    display: block;
    font-weight: bold;
    margin-bottom: 3px;
  }

  p {
    font-weight: 500;
  }
`;

interface DecimalFormatProps {
  price: number | null;
}

const DecimalFormat: React.FC<DecimalFormatProps> = ({ price }) => {
  if (price === null) {
    return null; // Or return an empty string or any other placeholder you prefer
  }

  return <>{price.toFixed(2)}</>;
};

const publicApi = new PublicApi();



type RequestStateType = {
  search: string;
  pageNumber :number;
  pageSize: number;
  sortBy:string;
  sortType:string;
};

const generateYearOptions = (): number[] => {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let year = currentYear-1; year >= 2021; year--) {
    years.push(year);
  }
  return years;
};

const ExplanationNotes = styled.div`
  margin-top: 3%;
  padding:20px;
  margin-bottom:6%;
  width:80%;
  font-size: 14px;
  line-height: 1.5;
  border: 3px solid #CDD3D866;
  border-radius : 16px;
`;
interface ExportResponse {
  fileContents: string;  // base64-encoded file content
  fileDownloadName: string;  // name of the file to be downloaded
  contentType: string;  // MIME type of the file
}
export const SubsentceReport = () => {
  const params = useParams();
  const [details, setDetails] = useState<RegisterDetailsDto>({});
  const [loading, setLoading] = useState(false);
  const [selectedEquipment, setSelectedEquipment] = useState<EquipmentDto>({});
  const [showEquipmentDetailsModal, setShowEquipmentDetailsModal] =
    useState(false);
  const navigate = useNavigate();

  const { t } = useTranslation();
  /**
   * Authorization
   */
  /**
   * States
   */
  const [data, setData] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedYear, setSelectedYear] = useState<any>();
  const [searchValue,setSearchValue] = useState("");
  const [isDescending, setIsDescending] = useState<boolean>(true);
  const [isLoadingExcel, setIsLoadingExcel] = useState(false);
  const [request, setRequest] = useState<RequestStateType>({
    search: "",
    pageNumber: 1,
    pageSize: 10,
    sortBy:'',
  sortType:''
  });

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request]);

  const handlePaginationChange = (pageNumber: number) => {
    setRequest((prevQuery: any) => ({ ...prevQuery, pageNumber }));
  };

  const onShowSizeChange = (pageNumber: number, pageSize: number) => {
    setRequest((prevQuery: any) => ({ ...prevQuery, pageNumber, pageSize }));
  };

  const handleYearChange = (value: any) => {
    // Check if the value is defined and has the 'value' property
    if (value ) {
      // Access the 'value' property safely
      setSelectedYear(value);
      setSearchValue(value);
      setRequest((prevQuery) => ({ ...prevQuery, search: value }));
   
    } else {
      // Handle the case where the value or value.value is undefined
      console.error("Invalid year value:", value);
    }
  };

   // Function to toggle sorting direction
   const toggleSorting = () => {
    setIsDescending((prevState) => !prevState);
  };
  const getData = async () => {
    setIsLoading(true);
    toggleSorting();
  
    const { data } : any = await publicApi.apiGetReportData(request);
  
    setData(data); // Adjust based on the actual response property
    setIsLoading(false);
  };
 
  const columns: ColumnsType<any> = [
    {
      title: t("reports.ordinalNumber", "Ordinal Number"),
      dataIndex: "ordinalNumber",
      key: "ordinalNumber",
      sorter: false,
    },
    {
      title: t("global.name-of-substance-mixture", "Name of substance (mixture)"),    
      dataIndex: "refrigerantTypeName",
      key: "refrigerantTypeName",
    },
    {
      title: t("global.chemical-formula", "Chemical formula"),
      dataIndex: "refrigerantTypeChemicalFormula",
      key: "refrigerantTypeChemicalFormula",

    },
    {
      title: t("global.symbol", "Symbol (mark)"),
      dataIndex: "refrigerantTypeASHRAEDesignation",
      key: "refrigerantTypeASHRAEDesignation",
    },
    {
      title: t("global.total-quantities", "Total quantities (kg)"),
      children: [{
        title: t("global.purchased-acquired", "Purchased/ acquired"),
        dataIndex: "totalPurchased",
        key: "totalPurchased",
      },
      {
        title: t("global.collected", "Collected"),
        dataIndex: "totalCollected",
        key: "totalCollected",
      },
      {
        title: t("global.renewed", "Renewed"),
        dataIndex: "totalRenewed",
        key: "totalRenewed",
      },
      {
        title: t("global.Sold", "Sold"),
        dataIndex: "totalSold",
        key: "totalSold",
      },
      {
        title: t("global.used", "Used (*)"),
        children: [
          {
            title: t("global.1", "(1)"),
            dataIndex: "totalUsed1",
            key: "totalUsed1",
          },
          {
            title: t("global.2", "(2)"),
            dataIndex: "totalUsed2",
            key: "totalUsed2",
          },
          {
            title: t("global.3", "(3)"),
            dataIndex: "totalUsed3",
            key: "totalUsed3",
          },
          {
            title: t("global.4", "(4)"),
            dataIndex: "totalUsed4",
            key: "totalUsed4",
          },
        ]
      },
      ]
    },
    {
      title: t("global.stock-balance", "Stock balance (kg) on ​​31.12"),
      dataIndex: "totalStockBalance",
      key: "totalStockBalance",
    },
  
  ];

  const isInitialLoading = isLoading && !data?.items;
  /**
   * Generate table data
   */
  const generateTableData = () => {
    return (data ?? []).map((item: any) => {
      const { id, refrigerantTypeName, ordinalNumber,refrigerantTypeChemicalFormula, refrigerantTypeASHRAEDesignation, totalPurchased, totalCollected, totalRenewed, totalSold, totalUsed1, totalUsed2, totalUsed3, totalUsed4, totalStockBalance } = item;
      return {
        key: id,
        ordinalNumber:ordinalNumber,
        refrigerantTypeName,
        refrigerantTypeChemicalFormula,
        refrigerantTypeASHRAEDesignation,
        totalPurchased: <DecimalFormat price={totalPurchased} />,
        totalCollected: <DecimalFormat price={totalCollected} />,
        totalRenewed: <DecimalFormat price={totalRenewed} />,
        totalSold: <DecimalFormat price={totalSold} />,
        totalUsed1: <DecimalFormat price={totalUsed1} />,
        totalUsed2: <DecimalFormat price={totalUsed2} />,
        totalUsed3: <DecimalFormat price={totalUsed3} />,
        totalUsed4: <DecimalFormat price={totalUsed4} />,
        // stateOfSubstance:<DecimalFormat price={stateOfSubstance} />,
        totalStockBalance: <DecimalFormat price={totalStockBalance} />,

        action: (
          <div className="table-actions" style={{ clear: "both" }}>
             <Tooltip title={t("global.view", "View")}>
            <Link to={`/reports/quantities-of-refrigerants/${id}`}>
              <Button className="btn-icon" type="default" shape="circle">
                <FeatherIcon icon="eye" size={17} />
              </Button>
            </Link>
            </Tooltip>
            <Button className="btn-icon" shape="circle">
            <Link to="#" title={t("global.delete", "Delete")}>
              <FeatherIcon icon="delete" size={17} />
              </Link>
            </Button>
          </div>
        ),
      };
    });
  };

  const onClickExport = async (exportType:number,callFrom:string,search:string) => {
      setIsLoadingExcel(true);
      const request = {
        exportType: exportType,
        callFrom : callFrom,
        search : selectedYear,
        TimeZone:'Asia/Calcutta',
        FilterType:0
      };
      try {
        const response = await publicApi.apiExportDataGet({ ...request });
        
        downloadFile(response?.data);
      } catch (error) {
        console.error("Error exporting data:", error);
      } finally {
        setIsLoadingExcel(false);
      }
  }


  const downloadFile = (response:any) => {
    const { fileContents, fileDownloadName, contentType } = response.value;
  
    // Convert base64 file content to binary data
    const byteCharacters = atob(fileContents);
    const byteNumbers = new Array(byteCharacters.length);
  
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
  
    const byteArray = new Uint8Array(byteNumbers);
  
    // Create a Blob from the byteArray, ensure the MIME type is for Excel
    const blob = new Blob([byteArray], { type: contentType || 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  
    // Create a link element to download the blob
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileDownloadName || 'export.xlsx'; // Provide a default file name
  
    // Append the link to the DOM
    document.body.appendChild(link);
  
    // Simulate a click on the link to trigger the download
    link.click();
  
    // Remove the link from the DOM
    document.body.removeChild(link);
  };

  const openEquipmentDetailsModal = (equipment: EquipmentDto) => {
    setSelectedEquipment(equipment);
    setShowEquipmentDetailsModal(true);
  };

  return (
    <>
    <div className="details-overlay overlay-1">
  <p className="title-text">
    {t("substance-report:title", "Substance Report")}
  </p>
</div>

<div className="filter-div">
  <Form.Item name="year">
    <Select
      showSearch
      className="select-year-ddl"
      placeholder={t("substance-report:year", "Year")}
      value={selectedYear}
      onChange={handleYearChange}
    >
      {generateYearOptions().map((year: any) => (
        <Option key={year} value={year}>
          {year}
        </Option>
      ))}
    </Select>
  </Form.Item>

  <Button 
    onClick={() => onClickExport(1, 'MVTEO Annual Report On Import/Export Of Ozone Depleting Substance', selectedYear)}
  >
    {isLoadingExcel ? " Exporting..." : "Excel (XLSX)"}
  </Button>
</div>

<Row>
  <TableWrapper className="table-responsive">
    {isInitialLoading ? (
      <Skeleton active paragraph={{ rows: 5 }} />
    ) : (
      <div className="table-container">
        <Table
          dataSource={generateTableData()}
          columns={columns}
          showSorterTooltip={false}
          loading={isLoading}
          pagination={{
            current: data?.pageIndex,
            total: data?.totalCount,
            showSizeChanger: true,
            pageSizeOptions: [10, 50, 100, 1000],
            onChange: handlePaginationChange,
            onShowSizeChange: onShowSizeChange,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
          }}
        />
      </div>
    )}
  </TableWrapper>
<div style={{display:'flex',justifyContent:'center',width:'100%'}}>
  <ExplanationNotes>
          <h5 className="h5" style={{color:'#055E9A'}}>{t("data-on-acquired-used-substances.title", 'Data on acquired/used substances for')}</h5>
          <Divider    style={{
        border: '1px solid #CDD3D866',
      }}/>
          <p style={{color:'#999999E5',padding:'10px 0px 10px 0px',letterSpacing:'2%',fontSize:'14px',fontWeight:'600'}}>{t("data-on-acquired-used-substances-paragraph1.title", '(*) Quantities used in the reporting year (kg) for / Quantities used in the reporting year (kg) for:')}</p>
          <p style={{color:'#999999E5',padding:'10px 0px 10px 0px',letterSpacing:'2%',fontSize:'14px',fontWeight:'600'}}>{t("data-on-acquired-used-substances-paragraph2.title", '(1) Charging/Recharging of Refrigeration and Air Conditioning Equipment and Heat Pumps / Charging/Recharging of Refrigeration and Air Conditioning Equipment and Heat Pumps')}</p>
          <p style={{color:'#999999E5',padding:'10px 0px 10px 0px',letterSpacing:'2%',fontSize:'14px',fontWeight:'600'}}>{t("data-on-acquired-used-substances-paragraph3.title", '(**) Condition of the substance: a) used for the first time; b) collected; c) collected and renewed / Condition of the substance: a) used for the first time; b) collected; c) collected and restored')}</p>

        </ExplanationNotes>
        </div>
</Row>

       
      
    </>
  );
};
