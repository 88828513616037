import { RegisterDetailsPage } from "components/register/details/RegisterDetailsPage";
import { Home } from "./pages/home/Home";
import CreateInternalRequestPage from "./pages/requests/CreateRequestsForm";
import ConfirmationPage from "pages/confirmation/ConfirmationPage";
import { CompanyList } from "pages/companyList/CompanyList";
import { SubsentceReport } from "pages/subsentanceReport/SubstanceReport";
import PrivacyLegislation from "pages/Legislation/Legislation";

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path: '/company-list/:type/:id',
    element: <CompanyList />
  },
  {
    path: '/report',
    element: <SubsentceReport />
  },
  {
    path: '/legislation',
    element: <PrivacyLegislation />
  },
  {
    path: '/requests/create/:type',
    element: <CreateInternalRequestPage />
  },
  {
    path: '/register-details/:registerId',
    element: <RegisterDetailsPage />
  },
  {
    path: '/confirmation',
    element: <ConfirmationPage />
  }
];

export default AppRoutes;
