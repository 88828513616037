import React from 'react';
import { List, Button, Row, Col, Divider } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import './registerListItem.css';  // Import the new CSS file

const OperatorItem = ({ name, registerId, companyType, address, licenseId, licenseDuration, nrOfCertifiedServiceTechnicians }) => {
  const { t } = useTranslation();
  const navigate = useNavigate()

  return (
    <List.Item className="company-list-item">
      <Row className="company-list-item-content" gutter={[16, 16]}>
        <Col xs={24} sm={12} md={7} className='title'> {name}</Col>
        <Col xs={24} sm={12} md={1}>
          <Divider type="vertical" className='show-divider' style={{ height: '70%', backgroundColor: '#055e9a8f', marginTop: '7px' }} />
        </Col>
        <Col xs={24} sm={12} md={9} className='title'>{address}</Col>
        <Col xs={24} sm={12} md={6} style={{ display: 'flex', justifyContent: 'end', marginTop: '10px' }}>
          <Button className="details-button" type="primary" size="small" onClick={() => navigate(`/register-details/${registerId}`)}>
            {t('global:details', 'Details')}
          </Button>
        </Col>
      </Row>
    </List.Item>

  );
};

const RegisterListItem = ({ searchResults }) => {
  const { t } = useTranslation();

  return (
    <div className="company-list-container">
      <Row className="company-list-header">
     
        <Col span={7} className='title'>{t('requests:company-type.1', 'Company')}</Col>
        <Col span={1}> <Divider type="vertical" style={{ height: '100%', backgroundColor: '#FFFFFF80' }} /></Col>
        <Col span={16} className='title'>{t('register-list:municipality', 'Municipality').replace(":"," ")}</Col>
      </Row>
      <List
        className="company-list"
        itemLayout="horizontal"
        dataSource={searchResults}
        renderItem={(operator) => (
          <OperatorItem
            name={operator.companyName}
            registerId={operator.id}
            companyType={operator.companyType}
            address={operator.municipality}
            licenseId={operator.licenseId}
            licenseDuration={operator.licenseDuration}
            nrOfCertifiedServiceTechnicians={operator.nrOfCertifiedServiceTechnicians}
          />
        )}
      />
      <div className="results-count">
        <span>{t('register-list:number-of-results', '{{dynamicValue}} results', { dynamicValue: searchResults?.length })}</span>
      </div>
    </div>
  );
};

export default RegisterListItem;